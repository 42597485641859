<template>
  <v-dialog v-model="show" max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editGroup"
        v-if="assignedRole.data.permissions.orgGrp.write">
        mdi-pencil
      </v-icon>
      <v-icon v-bind="attrs" v-on="on" small @click="deleteGroup" v-if="assignedRole.data.permissions.orgGrp.delete">
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-alert dense type="info" class="white--text alertBorder" width="100%" v-if="actionType === 'edit'">
        Please enable <strong>Default Telepresence Group</strong> for the group which will answer user queries via
        telepresence.
      </v-alert>
      <v-card-text v-if="actionType === 'edit'">
        <v-form ref="editGroupForm" lazy-validation>
          <v-row no-gutters>
            <v-col cols="4">
              <v-text-field v-model="group.name" label="Group Name" :rules="rules.nameRules" required class="mx-3">
              </v-text-field>
              <v-switch label="Default Telepresence Group" v-model="group.defaultTelepresence" class="mx-3"></v-switch>
              <v-textarea outlined label="Description" @keyup.enter="addNewRole" v-model="group.description" class="mx-3"
                :rules="rules.descriptionRules">
              </v-textarea>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4" align="center">
              <span class="text-subtitle-1 black--text py-3"> Add Kiosks </span>
              <v-combobox v-model="list" :rules="[(v) => !!v || 'Assign at-least one kiosk']" outlined dense class="mx-3"
                hide-selected hide-details="" :items="kiosks" label="Assign Kiosks" multiple @change="list = []">
                <template v-slot:item="data">
                  <v-list-item-content @click="addKiosk(data.item)">
                    <v-list-item-title v-html="data.item.data().name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-combobox>
              <v-sheet min-height="10" max-height="225" class="mb-5 overflow-y-auto" width="90%"
                v-if="addedKioskList.length > 0">
                <v-list>
                  <v-list-item v-for="(kiosk, i) in addedKioskList" :key="i">
                    <v-list-item-icon>
                      <v-icon> mdi-presentation-play </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ getKioskName(kiosk) }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="removeKiosk(kiosk)">
                        mdi-close
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col cols="4" align="center">
              <span class="text-subtitle-1 black--text my-3"> Add Users </span>
              <v-combobox v-model="list" :items="usersList" hide-selected multiple outlined @change="list = []"
                label="Add Users" hide-details="" dense class="mx-3">
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-avatar color="primary" size="36" class="white--text">
                      {{ getUserInitials(data.item.id).initial }}
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content @click="addUser(data.item)">
                    <v-list-item-title>{{ data.item.data().name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-combobox>
              <v-sheet min-height="10" max-height="225" class="mb-5 overflow-y-auto" width="90%"
                v-if="addedUsersList.length > 0">
                <v-list>
                  <v-list-item v-for="(user, i) in addedUsersList" :key="i">
                    <v-list-item-avatar color="primary" size="36" class="white--text">
                      <v-avatar color="primary" size="36" class="white--text">
                        {{ getUserInitials(user).initial }}
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      {{ getUserInitials(user).name }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="removeUser(user)">
                        mdi-close
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" align="end">
              <v-btn width="175" class="ma-3" color="error" @click="resetForm()" outlined>
                <v-icon left>mdi-close</v-icon>Close
              </v-btn>
              <v-btn width="175" class="ma-3" color="primary" @click="updateGroup()">
                <v-icon left>mdi-pencil</v-icon> Edit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="actionType === 'delete'">
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="show = !show" width="175"><v-icon left>mdi-close</v-icon>Close</v-btn>
        <v-btn color="primary" @click="confirmDeleteGroup" width="175"><v-icon left>mdi-delete</v-icon>Delete</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "editAndDeleteGroup",
  props: {
    groupIndex: Object,
  },
  data() {
    return {
      assignKiosk: false,
      show: null,
      actionType: "",
      formTitle: "",
      loading: false,
      addedUsersList: [],
      addedKioskList: [],
      list: [],
      group: {
        name: "",
        description: "",
        id: null,
        users: [],
        kiosk: [],
        defaultTelepresence: false
      },
      rules: {
        nameRules: [(v) => !!v || "Group name is required"],
        descriptionRules: [(v) => !!v || "Description is required"],
      },
      removedUsersList: [],
      removedKioskList: []
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "allRegisteredKiosks",
      "isSuperAdmin",
      "assignedRole"
    ]),
    usersList() {
      let user = [];
      this.allRegisteredUsers.forEach((users) => {
        user.push(users);
      });
      if (this.addedUsersList.length !== 0) {
        return user.filter((x) => {
          return !this.addedUsersList.some((y) => {
            return x.id === y;
          });
        });
      } else {
        return user;
      }
    },
    kiosks() {
      let kiosks = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kiosks.push(kiosk)
      );
      if (this.addedKioskList.length >= 1) {
        return kiosks.filter((x) => {
          return !this.addedKioskList.some((y) => {
            return x.id === y;
          });
        });
      } else {
        return kiosks;
      }
    },
  },
  methods: {
    editGroup() {
      this.formTitle = "Edit Group";
      this.actionType = "edit";
      this.group = Object.assign({}, this.groupIndex);
      this.group.id = this.groupIndex.id;
      if (this.groupIndex.kiosk.length > 0) {
        this.assignKiosk = true;
      }
      this.addedUsersList = [...this.group.users];
      this.addedKioskList = [...this.group.kiosk]
    },
    getKioskName(id) {
      let kioskInfo = this.allRegisteredKiosks.find(kiosk => kiosk.id === id)
      return kioskInfo.data().name
    },
    deleteGroup() {
      this.formTitle = "Are you sure you want to delete this group?";
      this.actionType = "delete";
    },
    updateGroup() {
      if (this.$refs.editGroupForm.validate()) {
        if (this.addedKioskList.length > 0) {
          if (this.addedUsersList.length > 0) {
            this.group.users = [...this.addedUsersList]
            this.group.kiosk = [...this.addedKioskList]
            this.loading = true;
            this.$store.dispatch("updateGroup", this.group).then(
              (response) => {
                this.$store.dispatch("deleteGroupFromUser", {
                  groupId: this.group.id,
                  removedUsers: this.removedUsersList
                }).then(() => {
                  this.removedUsersList = []
                })
                this.$store.dispatch("deleteGroupFromKiosk", {
                  groupId: this.group.id,
                  removedKiosks: this.removedKioskList
                }).then(() => {
                  this.removedKioskList = []
                })
                this.loading = false;
                this.show = false;
                this.$store.dispatch("showSnackBar", {
                  text: response,
                  color: "success",
                });
              },
              (error) => {
                this.$store.dispatch("showSnackBar", {
                  text: error,
                  color: "error",
                });
              }
            );
          } else {
            this.$store.dispatch("showSnackBar", {
              text: "Atleast 1 user is required to create a group",
              color: "error",
            });
          }
        } else {
          this.$store.dispatch("showSnackBar", {
            text: "Atleast 1 kiosk is required to create a group",
            color: "error",
          });
        }
      }
    },
    confirmDeleteGroup() {
      this.loading = false;
      // also delete the group from Users
      this.$store.dispatch("deleteGroup", this.groupIndex).then(
        (response) => {
          this.loading = false;
          this.show = false;
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "success",
          });
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );

      //call cloud function here
    },
    getUserInitials(id) {
      let userInfo = this.allRegisteredUsers.find((user) => user.id === id);
      let initials = { initial: "", name: "" };
      if (userInfo.data().name !== null || userInfo.data().name !== "") {
        let split = userInfo.data().name.split(" ");
        split.forEach((item) => {
          initials.initial += item[0];
        });
        initials.initial = initials.initial.substring(0, 2);
        initials.name = userInfo.data().name;
      }
      return initials;
    },
    addUser(user) {
      this.addedUsersList.push(user.id);
    },

    removeUser(id) {
      this.removedUsersList.push(id);
      this.addedUsersList.splice(this.addedUsersList.indexOf(id), 1);
    },
    addKiosk(kiosk) {
      this.addedKioskList.push(kiosk.id)
    },
    removeKiosk(id) {
      this.removedKioskList.push(id)
      this.addedKioskList.splice(this.addedKioskList.indexOf(id), 1);
    },
    resetForm() {
      this.show = false;
      this.addedUsersList = []
      this.addedKioskList = []
    }
  },
};
</script>
